import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import CLMPlanForm from "./CLMPlanForm";
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import { planService } from '../../../services/plan';
import { clmPlanService } from '../../../services/CLMplan';

import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class CLMPlanListingPage extends Component{
  constructor(props){
  	super(props);

  	this.state = {
  	  addModalForm  : false,
      editModalForm : false,
      sorted   : '',
      filtered : '',
      pageSize : 10,
  	};

  	this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  	this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData  = this.getPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
    this.submitForm= this.submitForm.bind(this);
    this.getDurationsCall= this.getDurationsCall.bind(this);
    this.closeModal = HF.closeModal.bind(this);
  };



  submitForm(formData, formType) {
    if (formType === 'add'){
        clmPlanService.storeCLMPlan(formData).then(res => {
            this.setState({addModalForm:false});
            this.pagination.dataCall();
          });
    }else if (formType === 'edit'){
        clmPlanService.editCLMPlan(formData, this.state.editRec.id).then(res => {
            this.setState({editModalForm:false});
            this.pagination.dataCall();
          });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id)
  {
    clmPlanService.getCLMPlan(id).then(res => {
        console.log(res);
        this.setState({editRec:res.data}, ()=>{
            this.setState({editModalForm:true});
        });
      });
  }

  deleteRecord(recID)
  {
    clmPlanService.deleteCLMPlan(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID)
  {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


getPaginationData(page, filter, sort, pageSize, status)
{
    this.setState({ pageSize: pageSize }, function () {
      clmPlanService.paginationData(page, filter, sort, pageSize, status,this.state.search);
    });
}

updateStatus = recID => (e) => {
// return false;
clmPlanService.updateCLMPlanStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
    response => {
    clmPlanService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
    this.pagination.dataCall();
    }
);
}

updateBlocked = recID => (e) => {
    // return false;
    clmPlanService.blockCLMRecord(recID).then(
        response => {
        clmPlanService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
        this.pagination.dataCall();
        }
    );
    }

  componentDidUpdate(prevProps, prevState)
  {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return clmPlanService.paginationData(data.page, data.filter, data.sort, data.pageSize,this.state.rec_status,this.state.search);
  }


  getDurationsCall(){
    clmPlanService.getCLMDuration().then(res => {
        console.log('Durations',res);
        this.setState({durations:res.data});
      });
  }

componentDidMount() {
    this.getDurationsCall();
  }


  render()
  {
  	const { filtered,editRec,durations } = this.state;
    const columns = [
        {
          Header:"CLM Plans Listing",
          headerClassName:'headerPagination',
          columns: [
            {
                Header: "Title",
                accessor: "title",
                sortable: false
            },
            {
                Header: "Duration",
                accessor: "duration",
                className: "",
                sortable: false
            },
            {
                Header: "amount",
                accessor: "amount",
                className: "",
                sortable: false
            },




            {
              Header: "Status",
              accessor: "is_active",
              Cell: ({row}) => (
                  <label className="container-check">
                    &nbsp;
                    <input
                      type="checkbox" name="is_active" className="checkbox" value={row.is_active? 1 : 0} onChange={this.updateStatus(row._original.id)}  checked={row.is_active?1:0}
                     /><span className="checkmark"></span>
                  </label>
                ),
              className: "",
              sortable:false
            },
            // {
            //   Header:"Actions",
            //   accessor: "id",
            //   Cell: ({row}) => (
            //     <div>
            //     <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Plan</Popover>}>
            //       <span>
            //       {
            //         <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(row.id)}>&nbsp;</button>
            //       }

            //       </span>
            //     </OverlayTrigger>
            //     <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">DeletePlan</Popover>}>
            //       <span>
            //       {
            //         <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
            //       }
            //       </span>

            //     </OverlayTrigger>
            //     </div>
            //     ),
            //   className: "justifyEnd text-right",
            //   headerClassName: "justifyEnd",
            //   sortable:false
            // }

          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={12}>
                            <div className="secHeading">CLM Plans Listing</div>
                          </Col>



                          <Col md={12}>
                            <div className="flexElem flexResponsive mb-lg" style={{"alignItems": "flex-start", "justifyContent": "space-between"}}>
                              <div className="">
                                <OpenModalButton
                                  openModal={HF.openModal.bind(this, "addModalForm")}
                                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn']}
                                  buttonName="Add CLM Plan"
                                  classIcon={['fa', 'fa-plus']}
                                />
                              </div>


                            </div>
                          </Col>




                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'Plans'}
                          defaultSorted={
                            [
                              {
                                id: 'id',
                                desc: true
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No Record found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                          </Col>
                      </Row>
                    </Grid>

                    { durations !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Plan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <CLMPlanForm
                                  closeModel={HF.closeModal.bind(this, "addModalForm")}
                                  formTypePro="add"
                                  recPro={OC.CLMPLAN}
                                  submitForm= { this.submitForm }
                                  componentPro="AdListing"
                                  durations={durations}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Add Record Modal End*/}

              		  {/*Edit Record Modal start*/}
                    { editRec &&
                      <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Plan</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                          <Card bsClass={["innerCard mb-none"]} content={
                            <CLMPlanForm
                              closeModel={HF.closeModal.bind(this, "editModalForm")}
                              formTypePro="edit"
                              recPro={editRec}
                              submitForm= { this.submitForm }
                              durations={durations}
                            />
                          } />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }







                	</div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
  	);
  };
}
const CLMPlanListing = CLMPlanListingPage;
export default CLMPlanListing;

import { adminConstants } from 'redux/constant/admin-constant';
import { alertActions } from './alert-actions';
import { history } from 'helper/history';
import { adminUserService } from 'services/adminUser';
import { helperFunctions as HF } from '../../helper/helperFunctions';

export const adminUserActions = {
    login,
    logout,
    dashboard,
    dashboardMenu,
};

function dashboardMenu(){
    return dispatch => {
        dispatch(request());
        adminUserService.dashboardMenu()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.GET_MENU_DASHBOARD_REQUEST } }
    function success(response) { return { type: adminConstants.GET_MENU_DASHBOARD_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.GET_MENU_DASHBOARD_FAILURE, payload:error } }
}

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));

        adminUserService.login(email, password)
            .then(
                user => {
                    dispatch(success(user));

                    if(HF.hasRole('admin') == false){
                        localStorage.removeItem('user');
                        history.push('/Admin/admin-login');
                    }else{
                        history.push('/Admin/dashboard')
                    }

                    // history.go(0);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(email) { return { type: adminConstants.LOGIN_ADMIN_REQUEST, payload:email } }
    function success(user) { return { type: adminConstants.LOGIN_ADMIN_SUCCESS, payload:user } }
    function failure(error) { return { type: adminConstants.LOGIN_ADMIN_FAILURE, payload:error } }
}

function logout() {
    return dispatch => {
        dispatch(request());
        adminUserService.logout()
            .then(
                response => {
                    dispatch(success(response));
                    console.log('logoutOutFromServer 3');
                    localStorage.removeItem('user');
                    history.push('/Admin/admin-login');
                },
                error => {
                    console.log('logoutOutFromServer 4');
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };


    function request() { return { type: adminConstants.LOGOUT_ADMIN_REQUEST } }
    function success(response) { return { type: adminConstants.LOGOUT_ADMIN_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.LOGOUT_ADMIN_FAILURE, payload:error } }
}

function dashboard(){
    return dispatch => {
        dispatch(request());
        adminUserService.dashboard()
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/Admin/dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.GET_DASHBOARD_REQUEST } }
    function success(response) { return { type: adminConstants.GET_DASHBOARD_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.GET_DASHBOARD_FAILURE, payload:error } }
}

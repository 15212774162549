import { SC } from '../helper/ServerCall';

export const splashService = {
    paginationData,
    paginationDataMyCarets,
    storeSplash,
    getSplash,
    editSplash,
    deleteSplash,
    updateSplashStatus,
    startDefaultSplash,
    endDefaultSplash,
    getSplashes,
    getVideos,
    createCustumCaret,
    getRandomSplash,
    getRandomMyCaret,
    getRandomMyVideos,
    showCaretDatails
}

function paginationData(user_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.postCall('splash/data/'+user_id,data);
}

function paginationDataMyCarets(user_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.postCall('splash/myCarets/'+user_id, data);
}



function updateSplashStatus(catId, data) {
    data['splash_id'] = catId;
    return SC.postCall('splash/listing/status',data);
}

function startDefaultSplash(catId, data) {
    console.log("startDefaultSplash",data);
    data['splash_id'] = catId;
    return SC.postCall('splash/set_start_default_splash',data);
}

function endDefaultSplash(catId, data) {
    console.log("endDefaultSplash",data);
    data['splash_id'] = catId;
    return SC.postCall('splash/set_end_default_splash',data);
}

function storeSplash(catData) {
    return SC.postCall('splash/listing', catData);
}

function getSplash(id) {

    return SC.getCall('splash/listing/' + id);
}

function editSplash(data, splashId) {
    data['splash_id'] = splashId;
    return SC.postCall('splash/listing', data);
}

function deleteSplash(catId) {
    return SC.deleteCall('splash/listing/' + catId);
}

function getSplashes(user_id, filter) {
    
    const params = new URLSearchParams({ filter }).toString();
    return SC.getCall(`splash/getSplashes/${user_id}?${params}`);
}

function getVideos(data, search) {
   
    const params = new URLSearchParams({ search }).toString();
    return SC.getCall(`splash/getVideos/${data}?${params}`);
}

function createCustumCaret(data) {
    return SC.postCall('splash/createCarets', data);
}

function getRandomSplash(user_id) {
    return SC.getCall('splash/getSplashesRandom/'+user_id);
}
function getRandomMyCaret(user_id) {
    return SC.getCall('splash/getRandomMyCaret/'+user_id);
}
function getRandomMyVideos(user_id) {
    return SC.getCall('splash/getRandomMyVideos/'+user_id);
}
function showCaretDatails(id) {
    return SC.postCall('splash/showCaret/'+ id);
}

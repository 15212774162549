import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,

} from "react-bootstrap";
import { Link } from 'react-router-dom';
import Logo from "../../assets/img/logo_frontEnd.png"
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { RiVideoLine } from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-table/react-table.css';
import { FaRegImage } from "react-icons/fa";
import PaymentAds from "../../assets/img/paymentAds.svg";
import ActiveAds from "../../assets/img/activeAds.svg";
import CliksAds from "../../assets/img/cliksAds.svg";
import video_clips from "../../assets/img/video_clips.png";
import { MdAudiotrack } from "react-icons/md";



import A3 from "../../assets/img/A3.svg";
import totalCompaign from "../../assets/img/totalCompaign.svg";
import Confirmalertfordelete from '../ThemeComponents/confirmAlertForDelete';
import OpenModalButton from '../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../helper/helperFunctions';
import Pagination from '../ThemeComponents/Pagination';
import { campaignService } from '../../services/campaign';
import { adService } from '../../services/ad';
import { licenseService } from '../../services/license';
import { splashService } from "../../services/splash";
import { musicService } from "../../services/music";

import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";

import Eye from "../../assets/img/eye.svg"
import eyeLogo from "../../assets/img/caretLogo.svg"

import AdVideoBanner from "../../assets/img/videoPreview.png"
import { Card, CardHeader, CardBody, CardTitle, CardText, Button } from 'reactstrap';

var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    const { id } = this.props.match.params;
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      openImageModal: false,
      campaignModalForm: false,
      video_url: '',
      image_url: '',
      sorted: '',
      filtered: '',
      pageSize: 10,
      totalAds: 0,
      campaignID: id,
      userID: user.data ? user.data.id : null,
      campaignDetails: [],
      splashList: [],
      myCaretList: [],
      myLogoList: [],
      myVideoList: [],
      myAudioList: [],
      adList: [],
      status_id: false,
      searchTerm: '',
      searchAd: '',
      isMobile: window.innerWidth < 1900,
      
    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.callFunction = this.callFunction.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.imageModal = this.imageModal.bind(this);
    this.handleNewAdClick = this.handleNewAdClick.bind(this);
  };


  handleNewAdClick = () => {
    this.setState({ campaignModalForm: true });
  }

  videoModal(url) {
    // console.log('url#############', url);
    this.setState({ video_url: url }, () => {
      this.setState({ openVideoModal: true });
      // console.log('url', this.state.video_url);
    });
  }
  imageModal(url) {
    // console.log('url#############', url);
    this.setState({ image_url: url }, () => {
      this.setState({ openImageModal: true });
      // console.log('url', this.state.image_url);
    });
  }

  componentDidMount() {
    // this.getCampaignDetails();
    this.getRandomSplashCall(this.state.userID);
    this.getRandomMyCaretCall(this.state.userID);
    this.getRandomMyVideosCall(this.state.userID);
    this.getRandomCaretLogoCall(this.state.userID);
    this.getSoundsDataCall(this.state.userID, this.state.searchTerm);
    this.getAdsCall(this.state.userID, this.state.searchAd);
    console.log("componentDidMount")
    window.addEventListener('resize', this.updateScreenSize);
  }

  // getCampaignDetails = () => {
  //   campaignService.getCampaignsSummary(this.state.userID).then(res => {
  //     console.log("getCampaignDetails", res.data);
  //     this.setState({ campaignDetails: res.data });
  //     this.setState({ userID: res.data.user_id });
  //   });
  // }

  getDurationsCall() {
    campaignService.getDuration().then(res => {
      // console.log('Durations', res);
      this.setState({ durations: res.data });
    });
  }

  callFunction(formData, formType) {
    if (formType === 'add') {
      this.setState({ addModalForm: false });
      this.pagination.dataCall();
    } else if (formType === 'edit') {
      this.setState({ editModalForm: false });
      this.setState({ campaignModalForm: false });
      this.pagination.dataCall();
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    campaignService.getCampaignAds(id).then(res => {
      // console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  deleteRecord(recID) {
    campaignService.deleteCampaignAds(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      licenseService.paginationData(this.state.userID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    // console.log('data @@@@@@@@@@@@ ', this.state.userID);

    return licenseService.paginationData(this.state.userID, data.page, data.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }

  getAdsCall = (val, searchTerm) => {
    adService.getAdsData(val, searchTerm).then((res) => {
      this.setState({ adList: res.data });
    });
  }
  getRandomSplashCall = (val) => {
    splashService.getRandomSplash(val).then((res) => {
      this.setState({ splashList: res.data });
    });
  };
  getRandomMyCaretCall = (val) => {
    splashService.getRandomMyCaret(val).then((res) => {
      this.setState({ myCaretList: res.data });
    });
  };
  getRandomMyVideosCall = (val) => {
    splashService.getRandomMyVideos(val).then((res) => {
      this.setState({ myVideoList: res.data });
    });
  };
  getSoundsDataCall = (val, searchTerm) => {
    musicService.getSoundsData(val, searchTerm).then((res) => {
      this.setState({ myAudioList: res.data });
    });
  };
  getRandomCaretLogoCall = (val) => {
    licenseService.getRandomCaretLogo(val).then((res) => {
      this.setState({ myLogoList: res.data });
    });
  };
  updateScreenSize = () => {
    this.setState({ isMobile: window.innerWidth < 1900 });
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenSize);
  }
  render() {
    const { filtered, editRec, durations } = this.state;
    const columns = [
      {
        Header: "License Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "My Logo",
            accessor: "video_url",
            Cell: ({ row, original }) => (
              // console.log('original', original.caret_logo),
              original.caret_logo ? (

                <div style={{ cursor: 'pointer', fontSize: '20px' }} >
                  <img style={{ hight: "25px", width: "40px" }} src={original.caret_logo} />
                </div>
              ) : (
                <div>
                  <button type='button' className="caretIcon orderDetailsAction">
                    &nbsp;</button>
                </div>
              )
            ),
            sortable: false
          },
          {
            Header: "My ^Caret",
            accessor: "caret_title",
            sortable: false
          },
          {
            Header: "License Type",
            accessor: "title",
            Cell: ({ original }) => (
              original.plan ? (
                <div>{original.plan.title}</div>
              ) : (
                <div>No Plan</div>
              )
            ),
            className: "",
            sortable: false
          },
          {
            Header: "Start Date",
            accessor: "startedOn",
            Cell: ({ row, original }) => (
              <div>
                {original.payments && original.payments[0] ? HF.TStoDate(original.payments[0].current_period_start) : ''}
              </div>
            ),
            sortable: false
          },
          {
            Header: "End Date",
            Cell: ({ value, original }) => (
              <div>
                {original.payments && original.payments[0] ? HF.TStoDate(original.payments[0].current_period_end) : ''}
              </div>
            ),
            sortable: false
          },
          {
            Header: "Status",
            Cell: ({ value, original }) => {
              const status = original.status ? original.status.title : "";
              return (
                <div>
                  {status}
                </div>
              );
            },
            className: "",
            sortable: false
          }

        ]
      }
    ];
    const videosToShow = this.state.isMobile ? 8 : 12;
    const videoListToRender = this.state.myVideoList.slice(0, videosToShow);
    const caretListToRender = this.state.myCaretList.slice(0, videosToShow);
    const splashListToRender = this.state.splashList.slice(0, videosToShow);
    const adListToRender = this.state.adList.slice(0, videosToShow);
    const audioListToRender = this.state.myAudioList.slice(0, videosToShow);
    
    return (
      <div className="content">
        <Grid fluid>
          
          <Row>
            <Col md={12}>
              <div className="secHeading">^Carets Dashboard</div>
              <Link to="/CLM/search" className="btn btnDark text-white" style={{ width: 'auto' }}>Purchase Additional ^Carets</Link>
              <div className="secHeading">My ^Caret Licenses</div>
            </Col>
            
          </Row>
          <Row>
            <Col md={12}>
              <Pagination
                ref={(p) => this.pagination = p}
                showPagination={false}
                columns={columns}
                pageSize={20}
                getDataCall={this.paginationCall}
                filterView={true}
                filterPlaceHolder={'License'}
                defaultSorted={
                  [
                    {
                      id: 'id',
                      desc: true
                    }
                  ]
                }
                // downloadData={true}
                // downloadFileName={'Orders'}
                // lowerContent = {null}
                // filterPlaceHolder = {'Orders'}
                noDataText='No Record found'
                getRowProps={this.getRowProps}
              // showAllToggle={true}
              />
            </Col>
            {
              <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                  <Modal.Title id='ModalHeader' className="headerTitle">Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12" style={{ textAlignVertical: "center", textAlign: "center" }}>
                      <video key={this.state.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={this.state.video_url} type="video/mp4" /></video>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
            {
              <Modal backdrop={'static'} show={this.state.openImageModal} onHide={HF.closeModal.bind(this, "openImageModal")} aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                  <Modal.Title id='ModalHeader' className="headerTitle">Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12" style={{ textAlignVertical: "center", textAlign: "center" }}>
                      <div className='text-center'>
                        <img src={this.state.image_url} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }



          </Row>
          {this.state.adList.length > 0 &&
            <Card className='gridCard'>
              <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                Ad Image / Video For Promotions
              </CardTitle>
              <CardBody className="px-1 py-1">
                <div className="splashGrid">
                  {adListToRender.map((ad, index) => (
                    <div key={index} onClick={() => this.videoModal(ad.video_url)} >
                      <div className="splashWrap splashDummyBG">
                        {/* <video src={ad.video_url} className="adImage" /> */}
                        <img src={ad.image_url} className="adImage" />
                        <div
                          className="adContent flexElem flexColumn mt-auto gap10p"
                          style={{ wordBreak: "break-word" }}
                        >
                          <div>
                            <h6
                              className="text-primary m-0 splashTitle">
                              Title:
                            </h6>
                            <h5 className="splashSubTitle">{ad.label}</h5>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          }
          
          {this.state.splashList.length > 0 &&
            <Card className='gridCard'>
              <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                Splash Image / Video Start and End Clip
              </CardTitle>
              <CardBody className="px-1 py-1">
                <div className="splashGrid">
                  {splashListToRender.map((splash, index) => (
                    <div key={index} onClick={() => this.videoModal(splash.video_url)} >
                      <div className="splashWrap splashDummyBG">
                        {/* <video src={splash.video_url} className="adImage" /> */}
                        <img src={splash.image_url} className="adImage" />
                        <div
                          className="adContent flexElem flexColumn mt-auto gap10p"
                          style={{ wordBreak: "break-word" }}
                        >
                          <div>
                            <h6
                              className="text-primary m-0 splashTitle">
                              Title:
                            </h6>
                            <h5 className="splashSubTitle">{splash.label}</h5>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          }

          {this.state.myLogoList.length > 0 &&
            <Card className='gridCard'>
              <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                Default Watermark / Your Caret Logo
              </CardTitle>
              <CardBody className="px-1 py-1">
                <div className="splashGrid">
                  {this.state.myLogoList.map((logo, index) => (
                    <div key={index} onClick={() => this.imageModal(logo.caret_logo)} >
                      <div className="splashWrap">
                        <div className='bgBlur' style={{ background: `url(${logo.caret_logo})` }}>
                          <img src={logo.caret_logo} className="adImage" />
                        </div>
                        <img src={logo.caret_logo} className="adImage" />
                        <div
                          className="adContent flexElem flexColumn mt-auto gap10p"
                          style={{ wordBreak: "break-word" }}
                        >
                          <div>
                            <h6
                              className="text-primary m-0 splashTitle">
                              Title:
                            </h6>
                            <h5 className="splashSubTitle">{logo.label}</h5>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>

            </Card>
          }

          {this.state.myCaretList.length > 0 &&
            <Card className='gridCard'>
              <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                Caret Video / Videos in a Frame
              </CardTitle>
              <CardBody className="px-1 py-1">
                <div className="splashGrid">
                  {caretListToRender.map((myCaret, index) => (
                    <div key={index} onClick={() => this.videoModal(myCaret.video_url)} >
                      <div className="splashWrap">
                        {/* <video src={myCaret.video_url} className="adImage" /> */}
                        <img src={myCaret.image_url} className="adImage" />
                        <div
                          className="adContent flexElem flexColumn mt-auto gap10p"
                          style={{ wordBreak: "break-word" }}
                        >
                          <div>
                            <h6
                              className="text-primary m-0 splashTitle">
                              Video Title:
                            </h6>
                            <h5 className="splashSubTitle">{myCaret.label}</h5>
                          </div>
                          <div style={{ marginTop: "au to" }}>
                            <h6
                              className="text-primary m-0 splashTitle">
                              Your ^Caret:
                            </h6>
                            <h5 className="splashSubTitle">{myCaret.license && myCaret.license.caret_title}</h5>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          }

          {this.state.myVideoList.length > 0 &&
            <Card className='gridCard'>
              <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                Your Uploaded Videos
              </CardTitle>
              <CardBody className="px-1 py-1">
                <div className="splashGrid">                  
                  {videoListToRender.map((myVideo, index) => (
                    <div key={index} onClick={() => this.videoModal(myVideo.video_url)}>
                      <div className="splashWrap">
                        <img src={myVideo.image_url} className="adImage" />
                        <div className="adContent flexElem flexColumn mt-auto gap10p" style={{ wordBreak: "break-word" }}>
                          <div>
                            <h6 className="text-primary m-0 splashTitle">Video Title:</h6>
                            <h5 className="splashSubTitle">{myVideo.label}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          }

          {this.state.myAudioList.length > 0 &&
            <Card className='gridCard'>
              <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                Your Uploaded Audio
              </CardTitle>
              <CardBody className="px-1 py-1">
                <div className="row">
                  {audioListToRender.map((audio, index) => (
                    <div className='col-12 col-sm-6 col-md-4 mb-2'>
                      {/* <div className='audioBG' style={{ ...(audio.image_url && { backgroundImage: `url(${audio.image_url})` }) }}> */}
                      <div className='audioBG'>
                        <div className="splashWrap1">
                          <div className="flexElem gap10p alignCenter mb-1">
                            {
                              audio.image_url?
                              (<img src={audio.image_url} className="roundedFull audioAVT" alt="Audio" />):
                              (
                                <img src={video_clips} className="roundedFull audioAVT" alt="Audio" />
                                
                              )
                            }
                            <div className='flexElem flexColumn gap5'>
                              <h6
                                className="text-primary m-0"
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                Audio Title:
                              </h6>
                              <h6
                                className="text-primar m-0"
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {audio.label}
                              </h6>
                            </div>
                          </div>
                          <audio src={audio.sound_url} className="w-100" controls style={{ height: 48 }} />
                          <div
                            className="adContent flexElem flexColumn mt-auto gap10p"
                            style={{ wordBreak: "break-word" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          }



        </Grid>
      </div>
    );
  };
}
const Dashboard = DashboardPage;
export default Dashboard;

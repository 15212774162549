import { SC } from '../helper/ServerCall';

export const licenseService = {
    paginationData,
    storeLicense,
    getLicense,
    editLicense,
    deleteLicense,
    updateLicenseStatus,
    getUsers,
    checkCaretAvailability,
    licenseStatus,
    getStatus,
    renewalLicense,
    uploadCaretLogo,
    CaretDefaultSettings,
    getLicenseData,
    getRandomCaretLogo,
    keywordCheckCaret,
    calculatePaymentDifference,
    fetchCurrentPricing,
    updateSubscriptionStatus,
    
    

}

function paginationData(user_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.postCall('license/data/'+user_id,data);
}

function checkCaretAvailability(keyword, classification, networth, google_search_volume, instagram, twitter) {
    return SC.getCall(`v1/caret/search?keyword=${keyword}&classification=${classification}&networth=${networth}&google_search_volume=${google_search_volume}&instagram=${instagram}&twitter=${twitter}`);
}
function  keywordCheckCaret(keyword) {
    return SC.getCall(`v1/caret/check?keyword=${keyword}`);
}

function getUsers() {
    return SC.getCall('ads/getUsers');
}


function storeLicense(catData) {
    return SC.postCall('license/listing', catData);
}

function getLicense(id) {

    return SC.getCall('license/listing/' + id);
}

function editLicense(data, splashId) {
    data['license_id'] = splashId;
    return SC.postCall('license/listing', data);
}

function deleteLicense(catId) {
    return SC.deleteCall('license/listing/' + catId);
}
function updateLicenseStatus(catId, data) {
    data['license_id'] = catId;
    return SC.postCall('license/listing/status',data);
}

function getStatus(filter) {
    const data = {'filter': filter};
    return SC.getCall('license/getStatus',data);
}

function licenseStatus(data) {
    return SC.postCall('license/licenseStatus', data);
}
function renewalLicense(data) {
    return SC.postCall('license/toggleLicenseCancel', data);
}
function uploadCaretLogo(data) {
    return SC.postCall('license/uploadLogo', data);
}
function CaretDefaultSettings(data) {
    return SC.postCall('license/defaultSettings', data);
}

function getLicenseData(user_id) {
    return SC.getCall('license/getLicense/'+user_id);
}
function getRandomCaretLogo(user_id) {
    return SC.getCall('license/getCaretLogoRendom/'+user_id);
}

function calculatePaymentDifference(data) {
    return SC.postCall('license/calculatePaymentDifference', data);
}

function fetchCurrentPricing(stripe_id) {
    console.log('amount',stripe_id)
    return SC.getCall(`license/curntPlan?stripe_id=${stripe_id}`);
}

function updateSubscriptionStatus(data) {
    return SC.postCall('license/changeSubscription', data);
}




// var dateFormat = require('dateformat');
var moment = require("moment");

export const objectsConstants = {
    USER: {
        user_id: null,
        name: "",
        email: "",
        username: "",
        phone: "",
        password: "",
        confirm_password: "",
        roles: [],
        profile: {
            business_name: "",
            first_name: "",
            industry_id: "",
            last_name: "",
            user_bio: "",
            user_photo: "",
        },
        image: "",
        is_active: 0
    },
    AUDIO: {
        user_id: null,
        sound_title: "",
        sound_url: "",
        image_url: "",
        sound_description: "",
        categories: [],
        image: "",
        audioFile: "",
        is_active: 0
    },
    VIDEO: {
        video_title: "",
        video_url: "",
        image_url: "",
        video_description: "",
        users: [],
        image: "",
        videoFile: "",
        is_active: 0,
        is_allow_comments: 0,
        is_allow_caretCreation: 0
    },
    SPLASH: {
        splash_title: "",
        video_url: "",
        image_url: "",
        is_active: 0,
        start_default: 0,
        end_default: 0,
        user_id: ""
    },
    ADV: {
        ad_title: "",
        video_url: "",
        image_url: "",
        ad_description: "",
        user_id: "",
        image: "",
        videoFile: "",
        ad_button_text: "",
        ad_button_link: ""
    },
    LICENSE: {
        user_id: "",
        is_active: 0,
        caret_title: "",
        company_name: "",
        subscription_plan: "",
        subscription_amount: "",
        subscription_status: "",
        cancel_at_period_end: "",
        current_period_end: ""
    },
    CAMPAIGN: {
        user_id: "",
        campaign_title: "",
        details: { ages: "", gender: "", location: "" },
        is_active: 0,
        ad_button_text: "",
        ad_button_link: ""
    },
    CAMPAIGN: {
        user_id: "",
        campaign_title: "",
        details: { ages: "", gender: "", location: "" },
        is_active: 0
    },
    CAMPAIGNADS: {
        campaign_id: "",
        ad_id: "",
        plan_id: "",
        status_id: null,
        adsData: [{ ad_id: "", plan_id: "" }]
    },
    PLAN: {
        title: "",
        duration: "",
        amount: "",
        position: "",
        is_active: 0
    },
    CLMPLAN: {
        title: "",
        duration: "",
        amount: "",
        is_active: 0
    },
    SETTING: {
        name: "",
        status: "",
        setting_type: "",
        value: ""
    },
    PAGES: {
        page_title: "",
        page_slug: "",
        page_description: "",
        meta_title: "",
        meta_description: "",
        meta_tags: "",
        is_active: 0
    },

    PERMISSION: {
        name: "",
        permission_type: "",
        is_menu: "",
        label: "",
        icon: "",
        description: "",
        parent_label: "",
        order: "",
        path: "",
        module_id: ""
    },
    HASH: {
        title: "",
        category_id: "",
        is_active: 0
    },
    KEYWORD: {
        title: "",
        type: "",
        is_active: 0
    },
    CARETPRICING: {
        title: "",
        plan_id: "",
        networth: "",
        instagram_followers: "",
        twitter_followers: "",
        google_searches: "",
        one_year_license: "",
        two_year_license: "",
        three_year_license: "",
        special_instructions: "",
        sales_price: "",
        contact_sales: "",
        contact_amount: "",
        is_active: 0
    },



    RANDOMCOLORS: [
        "#95c581",
        "#fd7295",
        "#9b6ab4",
        "#5ec9c8",
        "#fe9e55",
        "#ad84bf",
        "#ffb58b",
        "#88ce56",
        "#ff7994",
        "#a368b9"
    ],

    DEFAULTCOUNTRY: "United States"
};

import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import DatePicker from "react-datepicker";
import Select from 'react-select';

import 'react-table/react-table.css';
import KeywordForm from "./KeywordForm";
import Confirmalertfordelete from '../../../ThemeComponents/confirmAlertForDelete';
import OpenModalButton from '../../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../../helper/helperFunctions';
import Pagination from '../../../ThemeComponents/Pagination';
import { planService } from '../../../../services/plan';
import { clmPlanService } from '../../../../services/CLMplan';
import { keywordService } from '../../../../services/keyword';


import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
import { filter } from 'lodash';
var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class KeywordListingPage extends Component{
  constructor(props){
  	super(props);

      // ====================================
      this.dateValidator = new ReactValidator();
      var today = new Date();
      today.setDate(today.getDate() + 1);
      var lastmonth = new Date();
      lastmonth.setMonth(lastmonth.getMonth() - 1);
      var startDate = lastmonth.getFullYear() + '-' + (lastmonth.getMonth() + 1) + '-' + lastmonth.getDate();
      var endDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      // ====================================

  	this.state = {
  	  addModalForm  : false,
      editModalForm : false,
      sorted   : '',
      filtered : '',
      pageSize : 10,
      search:{
        fromDate : startDate,
        toDate: endDate,
      },
      filter: '',
  	};

  	this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  	this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData  = this.getPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
    this.submitForm= this.submitForm.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.closeModal = HF.closeModal.bind(this);

  };



  submitForm(formData, formType) {
    if (formType === 'add'){
        keywordService.storeKeyword(formData).then(res => {
            this.setState({addModalForm:false});
            this.pagination.dataCall();
          });
    }else if (formType === 'edit'){
        keywordService.editKeyword(formData, this.state.editRec.id).then(res => {
            this.setState({editModalForm:false});
            this.pagination.dataCall();
          });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id)
  {
    keywordService.getKeyword(id).then(res => {
        console.log(res);
        this.setState({editRec:res.data}, ()=>{
            this.setState({editModalForm:true});
        });
      });
  }

  deleteRecord(recID)
  {
    keywordService.deleteKeyword(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID)
  {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


getPaginationData(page, filter, sort, pageSize, status)
{
    this.setState({ pageSize: pageSize }, function () {
      keywordService.paginationData(page, filter, sort, pageSize, status,this.state.search);
    });
}

DateChangeHandler = (value, field) => {
  const temp = cloneDeep(this.state.search);
  temp[field] = value === null ? '' : moment(value).format("YYYY-MM-DD");

  this.setState({ search : temp  }, () => {

      if (this.dateValidator.allValid()) {
        keywordService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      } else {
          this.dateValidator.showMessages();
          this.forceUpdate();
      }
  })
}
dateElement(label, name, value, rules) {
  var selectedDate =  this.state.search[name] !== '' ? new Date(this.state.search[name]) :'';
  const { fromDate, toDate } = this.state.search;
  console.log(value);
  return (
      <div className='form-group fullCalendar' id={name}>
            <label>{label}</label>
            <DatePicker className="form-control calender_icon"  selected={  selectedDate } name={name} onChange={ (event) => this.DateChangeHandler(event, name) } />
            {
                ( fromDate !== '' && toDate !== '' ) &&
                this.dateValidator.message(name, value, rules)
            }
      </div>
  );
}

updateStatus = recID => (e) => {
// return false;
keywordService.updateKeywordStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
    response => {
      keywordService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
    this.pagination.dataCall();
    }
);
}

updateBlocked = recID => (e) => {
    // return false;
    clmPlanService.blockCLMRecord(recID).then(
        response => {
        clmPlanService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
        // this.pagination.dataCall();
        }
    );
    }

  componentDidUpdate(prevProps, prevState)
  {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return keywordService.paginationData(data.page, data.filter || this.state.filter, data.sort, data.pageSize,this.state.rec_status,this.state.search);
  }



  handleTypeChange(selectedOption) {
    console.log("selectedOption", selectedOption);

    // Set the filter state and run the callback after the state is updated
    this.setState({
        filter: selectedOption ? selectedOption.value : ''
    }, () => {
        // Ensure this runs only after the state update is complete
        this.pagination.dataCall();
    });
}




  render()
  {
  	const { filtered,editRec,durations } = this.state;
    const { toDate, fromDate } = this.state.search;
    const options = [
      { value: '', label: 'All' },
      { value: 'special_pricing', label: 'Special pricing' },
      { value: 'not_for_sale', label: 'Not for sale' },
      { value: 'assigned', label: 'Assigned' },
      { value: 'naughty', label: 'Naughty' },
    ];

    const columns = [
        {
          Header:"Keyword Listing",
          headerClassName:'headerPagination',
          columns: [
            {
                Header: "Title",
                accessor: "title",
                sortable: false
            },
            {
                Header: "Type",
                accessor: "type",
                className: "",
                sortable: false
            },
            
            {
              Header: "Status",
              accessor: "is_active",
              Cell: ({row}) => (
                  <label className="container-check">
                    &nbsp;
                    <input
                      type="checkbox" name="is_active" className="checkbox" value={row.is_active? 1 : 0} onChange={this.updateStatus(row._original.id)}  checked={row.is_active?1:0}
                     /><span className="checkmark"></span>
                  </label>
                ),
              className: "",
              sortable:false
            },
            {
              Header:"Actions",
              accessor: "id",
              Cell: ({row}) => (
                <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit keyword</Popover>}>
                  <span>
                  {
                    <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(row.id)}>&nbsp;</button>
                  }

                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete keyword</Popover>}>
                  <span>
                  {
                    <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
                  }
                  </span>

                </OverlayTrigger>
                </div>
                ),
              className: "justifyEnd text-right",
              headerClassName: "justifyEnd",
              sortable:false
            }

          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={12}>
                            <div className="secHeading">Blocked Carets Listing</div>
                          </Col>

                          <Col md={12}>
                            <div className="flexElem flexResponsive mb-lg" style={{"alignItems": "flex-start", "justifyContent": "space-between"}}>
                              <div className="">
                                <OpenModalButton
                                  openModal={HF.openModal.bind(this, "addModalForm")}
                                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn']}
                                  buttonName="Add Blocked Carets"
                                  classIcon={['fa', 'fa-plus']}
                                />
                              </div>
                            </div>
                          </Col>

                          <div className='col-md-12 mt-1'>
                            <Col md={2}>
                                <div className='row flexElem flexResponsive align-items-center' >
                                    <div className="col-md-12" >
                                    {
                                        this.dateElement('Date From', 'fromDate', fromDate!=='' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.search.toDate) }])
                                    }
                                    </div>
                                </div>
                            </Col>
                            <Col md={2} >
                                <div className='row flexElem flexResponsive align-items-center' >
                                    <div className="col-md-12" >
                                    {
                                        this.dateElement('Date To', 'toDate', toDate!=='' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.search.fromDate) }])
                                    }
                                    </div>

                                </div>
                            </Col>

                            <Col md={3} >
                            <div className="form-group">
                        <label htmlFor="typeSelect">
                            filter by type<span className="requiredClass"></span>
                        </label>
                        <Select
                            name="typeSelect"
                            options={options}
                            className="basic-single"
                            classNamePrefix="select"
                            value={options.find(option => option.value === this.state.filter)} // Set the current selected value
                            onChange={this.handleTypeChange} // Handle dropdown change
                        />
                    </div>
                            </Col>
                            </div>




                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'Keyword'}
                          defaultSorted={
                            [
                              {
                                id: 'id',
                                desc: true
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No Record found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                          </Col>
                      </Row>
                    </Grid>

                    { durations !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Keyword</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <KeywordForm
                                  closeModel={HF.closeModal.bind(this, "addModalForm")}
                                  formTypePro="add"
                                  recPro={OC.KEYWORD}
                                  submitForm= { this.submitForm }
                                  componentPro="AdListing"
                                  durations={durations}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Add Record Modal End*/}

              		  {/*Edit Record Modal start*/}
                    { editRec &&
                      <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Keyword</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                          <Card bsClass={["innerCard mb-none"]} content={
                            <KeywordForm
                              closeModel={HF.closeModal.bind(this, "editModalForm")}
                              formTypePro="edit"
                              recPro={editRec}
                              submitForm= { this.submitForm }
                              durations={durations}
                            />
                          } />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }







                	</div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
  	);
  };
}
const KeywordListing = KeywordListingPage;
export default KeywordListing;

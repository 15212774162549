
import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { RiVideoLine } from 'react-icons/ri';
import SplashForm from "./SplashForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import DatePicker from "react-datepicker";
import { splashService } from '../../../services/splash';
import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import Eye from "../../../assets/img/eye.svg"


var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class SplashListingPage extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem('user'));
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      campaignModal: false,
      campaignData: OC.CAMPAIGN,
      video_url: '',
      selectedVideo: [],
      sorted: '',
      filtered: '',
      search: { a: 1 },
      pageSize: 10,
      userID: user.data ? user.data.id : null,
    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getUsersCall = this.getUsersCall.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.callFunction = this.callFunction.bind(this);
  };

  callFunction(formData, formType) {
    this.setState({ campaignData: false });
  }

  videoModal(url) {
    this.setState({ video_url: url }, () => {
      console.log('video_url', this.state.video_url);
      this.setState({ openVideoModal: true });
      console.log('uopenVideoModalrl', this.state.openVideoModal);
      console.log('url', this.state.video_url);
    });

  }

  submitForm(formData, formType) {
    if (formType === 'add') {
      splashService.storeSplash(formData).then(res => {
        this.setState({ addModalForm: false });
        this.pagination.dataCall();
      });
    } else if (formType === 'edit') {
      splashService.editSplash(formData, this.state.editRec.id).then(res => {
        this.setState({ editModalForm: false });
        this.pagination.dataCall();
      });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    splashService.getSplash(id).then(res => {
      console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  // showCampaignModal = (id, user_id) => {

  //   let cmpdata = { ...this.state.campaignData };
  //   cmpdata['user_id'] = user_id;
  //   cmpdata['ad_id'] = id;
  //   this.setState({ campaignData: cmpdata });
  //   this.setState({ campaignModal: true });
  // }


  deleteRecord(recID) {
    splashService.deleteSplash(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      splashService.paginationData(this.state.userID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  updateStatus = recID => (e) => {
    // return false;
    splashService.updateSplashStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        splashService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  updateBlocked = recID => (e) => {
    // return false;
    splashService.blockRecord(recID).then(
      response => {
        splashService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  StartDefaultSplashStatus = recID => (e) => {
    // return false;
    splashService.startDefaultSplash(recID, { start_default: e.target.checked ? 1 : 0 }).then(
      response => {
        splashService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  EndDefaultSplashStatus = recID => (e) => {
    // return false;
    splashService.endDefaultSplash(recID, { end_default: e.target.checked ? 1 : 0 }).then(
      response => {
        splashService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }



  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return splashService.paginationData(this.state.userID, data.page, data.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }


  getUsersCall() {
    splashService.getUsers().then(res => {
      console.log('Users', res);
      this.setState({ users: res.data });
    });
  }

  componentDidMount() {
    // this.getUsersCall();
  }



  render() {
    const { filtered, editRec, users, campaignData } = this.state;
    const columns = [
      {
        Header: "Splash Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Video",
            accessor: "video_url",
            Cell: ({ row, original }) => (
              original.image_url ? (
                <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.video_url)}>
                  <img style={{ hight: "25px", width: "40px" }} src={original.image_url} />
                </div>
              ) : (
                <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.video_url)}>
                  <RiVideoLine size={40} />
                </div>
              )
            ),
            className: "",
            sortable: false
          },
          {
            Header: "Title",
            accessor: "splash_title",
            className: "",
            sortable: false
          },

          {
            Header: "Status",
            accessor: "is_active",
            Cell: ({ row }) => (
              <label className="container-check">
                &nbsp;
                <input
                  type="checkbox" name="is_active" className="checkbox" value={row.is_active ? 1 : 0} onChange={this.updateStatus(row.id)} checked={row.is_active ? 1 : 0}
                /><span className="checkmark"></span>
              </label>
            ),
            className: "",
            sortable: false
          },

          {
            Header: "Start Default",
            accessor: "start_default",
            Cell: ({ row }) => (
              <label className="container-check">
                &nbsp;
                <input
                  type="checkbox" name="start_default" className="checkbox" value={row.start_default ? 1 : 0} onChange={this.StartDefaultSplashStatus(row.id)} checked={row.start_default ? 1 : 0}
                /><span className="checkmark"></span>
              </label>
            ),
            className: "",
            sortable: false
          },

          {
            Header: "End Default",
            accessor: "end_default",
            Cell: ({ row }) => (
              <label className="container-check">
                &nbsp;
                <input
                  type="checkbox" name="end_default" className="checkbox" value={row.end_default ? 1 : 0} onChange={this.EndDefaultSplashStatus(row.id)} checked={row.end_default ? 1 : 0}
                /><span className="checkmark"></span>
              </label>
            ),
            className: "",
            sortable: false
          },


          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ row, original }) => (
              <div>



                {/* <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Add To Campaign</Popover>}>
                        <span>
                        {
                            <button type='button' className="orderDetailsAction" onClick={() => this.showCampaignModal(row.id,original.user_id)}> C </button>
                        }

                    </span>
                </OverlayTrigger> */}

                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Splash</Popover>}>
                  <span>
                    {
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(row.id)}>&nbsp;</button>
                    }

                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Splash</Popover>}>
                  <span>
                    {
                      <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
                    }
                  </span>

                </OverlayTrigger>
              </div>
            ),
            className: "justifyEnd text-right",
            headerClassName: "justifyEnd",
            sortable: false
          }


        ]
      }
    ];

    return (
      <div className="content pl-2-5F">
        <Row>
          <Col md={12}>
            <div className="secHeading">Splash Listing</div>
            <OpenModalButton
              openModal={HF.openModal.bind(this, "addModalForm")}
              classButton={['btn btnDark text-white w-autoF']}
              buttonName="Add Splash"
              classIcon={['fa', 'fa-plus']}
            />
          </Col>
          <Col md={12} className="mt-10">
            <Pagination
              ref={(p) => this.pagination = p}
              showPagination={false}
              columns={columns}
              pageSize={20}
              getDataCall={this.paginationCall}
              filterView={true}
              filterPlaceHolder={'Splash'}
              defaultSorted={
                [
                  {
                    id: 'id',
                    desc: true
                  }
                ]
              }
              // downloadData={true}
              // downloadFileName={'Orders'}
              // lowerContent = {null}
              // filterPlaceHolder = {'Orders'}
              noDataText='No Record found'
              getRowProps={this.getRowProps}
            // showAllToggle={true}
            />
          </Col>
        </Row>
        {users !== null &&
          <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" className="modalLG modal-lg mx-auto">
            <Modal.Header closeButton>
              <Modal.Title id='ModalHeader' className="headerTitle">Add Splash</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Card bsClass={["innerCard mb-none"]} content={
                    <SplashForm
                      closeModel={HF.closeModal.bind(this, "addModalForm")}
                      formTypePro="add"
                      recPro={OC.SPLASH}
                      user_id={this.state.userID}
                      submitForm={this.submitForm}
                      componentPro="AdListing"
                      users={users}
                    />
                  } />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        }
        {/*Add Record Modal End*/}

        {/*Edit Record Modal start*/}
        {editRec &&
          <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" className="modalLG modal-lg mx-auto">
            <Modal.Header closeButton>
              <Modal.Title id='ModalHeader' className="headerTitle">Edit Splash</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Card bsClass={["innerCard mb-none"]} content={
                    <SplashForm
                      closeModel={HF.closeModal.bind(this, "editModalForm")}
                      formTypePro="edit"
                      recPro={editRec}
                      user_id={this.state.userID}
                      submitForm={this.submitForm}
                      users={users}
                    />
                  } />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        }


        {
          <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
            <Modal.Header closeButton>
              <Modal.Title id='ModalHeader' className="headerTitle">Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12" style={{ textAlignVertical: "center", textAlign: "center" }}>
                  <video key={this.state.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={this.state.video_url} type="video/mp4" /></video>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        }

      </div>
    );
  };
}
const SplashListing = SplashListingPage;
export default SplashListing;



import React from 'react';
import {
  Button
} from "react-bootstrap";


import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';

class CLMPlanForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			plan: {
				...this.props.recPro,
                title: 'corporate', 
			},
		    submitted: false,
		    formType: this.props.formTypePro 	|| '',
		};

		this.handleRec = this.handleRec.bind(this);
        this.handleRecSelect = this.handleRecSelect.bind(this);
  		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}



	handleRec(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.plan };
	    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({plan: recState});
	}

    handleRecSelect(selectedOptionType, info) {
        console.log("selectedOptionType ######## ",selectedOptionType, info)
        if (info['action'] === 'select-option') {
          let recState = { ...this.state.plan };
          recState[info['name']] = selectedOptionType['value'];
          console.log(recState);
          this.setState({plan: recState});
        }
    }

	handleSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid()) {
			this.setState({submitted: true}, function(){
				this.props.submitForm(this.state.plan, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}


	render(){
		const { plan,formType, allowedFormatsStringMime, allowedFormatsStringExt, allowedFormatsVideoMime, allowedFormatsVideoExt} = this.state;
		const { durations } = this.props;
        console.log('plan',plan,durations);
		return(
			<form onSubmit={this.handleSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="ad_id" className="form-control"  value={plan.id}/>
			    }
                 <div className="row">
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="duration">Select Duration<span className="requiredClass">*</span></label>
                            <Select
                                value={HF.returnLabelWithValue(plan.duration, durations)}
                                onChange={this.handleRecSelect}
                                name='duration'
                                options={durations}
                                isSearchable={true}
                                isMulti={false}
                                placeholder={'Select duration'}
                                isClearable={false}
                            />

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className=''>
                            <label className="container-check checkbox_down"> Is Active?
                            <input type="checkbox" name="is_active" className="checkbox" value={plan.is_active? 1 : 0} onChange={this.handleRec} checked={plan.is_active?1:0} />
                            <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>

                </div>

			  <div className="row">
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="title">Title<span className="requiredClass">*</span></label>
                            <input type="text" name="title" className="form-control"  value={plan.title} readOnly />
                            {this.validatorForm.message('title', plan.title, 'required|max:250')}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="amount">Amount<span className="requiredClass">*</span></label>
                            <input type="text" name="amount" className="form-control"  value={plan.amount} onChange={this.handleRec} />
                            {this.validatorForm.message('amount', plan.amount, 'required|max:250')}
                        </div>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="position">Position<span className="requiredClass">*</span></label>
                            <input type="text" name="position" className="form-control"  value={plan.position} onChange={this.handleRec} />
                            {this.validatorForm.message('position', plan.position, 'required|numeric|max:5')}
                        </div>
                    </div>
                </div> */}






			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default CLMPlanForm;

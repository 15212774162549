import React from 'react';
import {
  Button
} from "react-bootstrap";


import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import { duration } from 'moment';
import fileUploadIcon from "../../../assets/img/upload.svg";

class VideoForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			video: {
				...this.props.recPro,
				duration:'',
			},
		    submitted: false,
		    errorImg: '',
		    formType: this.props.formTypePro 	|| '',
			allowedFormatsStringMime : 'image/jpeg, image/png',
			allowedFormatsStringExt : 'jpg, png',
			allowedFormatsVideoMime: 'video/quicktime, video/mp4, video/x-flv, video/webm',
			allowedFormatsVideoExt: 'mov, mp4, flv, webm',
			imagePreviewUrl:this.props.recPro ? this.props.recPro.image : null,
			isDragging: false,
			error: '',
			videoPreviewUrl: this.props.recPro ? this.props.recPro.videoFile : null,
			videoFileName: '',
			videoAdded: false,
			videoDuration: '',
			isDraggingVid: false,
		};

		this.handleRec = this.handleRec.bind(this);
  		this.handleSubmitForm = this.handleSubmitForm.bind(this);
  		this.handleChangeCategory = this.handleChangeCategory.bind(this);
	}
	handleChangeCategory(selectedOption, name){
		if (name.action === 'select-option'){
			this.setState(prevState => ({
			    video: {
			        ...prevState.video,
			        users:  [...prevState.video.users, name.option.value]
			    }
			}));
		}
		if (name.action === 'remove-value'){
			const catTemp = this.state.video.users;
			catTemp.splice(catTemp.indexOf(name.removedValue.value), 1);
			this.setState(prevState => ({
				video: {
			        ...prevState.video,
			        users: catTemp
			    }
			}))
		}
	}
	handleRec(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.video };
	    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({video: recState});
	}
	handleSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid()) {
			this.setState({submitted: true}, function(){
				this.props.submitForm(this.state.video, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}

	extractImageData =  (value) =>  {
		const file = {};
		file['file'] = value['file'];
		file['name'] = value['name'];
		file['size'] = value['size'];
		file['extension'] = HF.returnExtFromImage(value['name']);
		file['preview'] = value['preview'];
		file['type'] = value['type'];
		file['lastModified'] = value['lastModified'];
		return file;
	}
	setResourceFile = (key, value) => {
		this.setState({
			video: {...this.state.video, image:this.extractImageData(value) },
		});
	}
	removeFile = (key, index) =>{
		this.setState({
			video: {...this.state.video, image:null },
		});
	}


	setVideoFileOLD = (key, value) => {
		console.log('value',value);
		this.setState({
			video: {...this.state.video, 
				videoFile:this.extractImageData(value), 
				duration: value.duration,
			},
		});
    }

	removeVideoFile = (key, index) =>{
		this.setState({
			video: {...this.state.video, videoFile:null },
		});
	};

	setVideoFile = (event) => {
		const file = event.target.files[0];
		if (!file)
			return;
		const fileData = {};
		fileData['name'] = file['name'];
		fileData['size'] = file['size'];
		fileData['extension'] = HF.returnExtFromImage(file['name']).toLowerCase();
		fileData['type'] = file['type'];
		fileData['lastModified'] = file['lastModified'];

		const video = document.createElement('video');
		const reader = new FileReader();
		video.onloadedmetadata = () => {
			const duration = Math.floor(video.duration);
			if (duration > 180) {
				this.setState({ error: 'Video duration exceeds the maximum allowed (3 minutes)' });

			} else if (file.size > 500 * 1024 * 1024) {
				this.setState({ error: 'Video size exceeds the maximum allowed (500 MB)' });

			} else {
				this.setState({ error: '' });
				const videoPreviewUrl = URL.createObjectURL(file);
				fileData['preview'] = videoPreviewUrl;
				this.setState({ videoDuration: duration, error: null });
				this.setState({ videoPreviewUrl: null },
					() => {
						this.setState({ videoPreviewUrl: videoPreviewUrl });

					})
				this.setState({ videoAdded: true });
				this.setState({ videoFileName: fileData["name"] });
				this.setState({
					video: { ...this.state.video, videoFile: fileData, duration: duration },
				});
			}
		};
		reader.readAsDataURL(file);
		reader.onload = () => {
			fileData['file'] = reader.result;
		};
		video.src = URL.createObjectURL(file);
		this.setState({
			video: { ...this.state.video, videoFile: fileData },
		});
	};


	handleDragOverVideo = (event) => {
		event.preventDefault();
		this.setState({ isDraggingVid: true });
	};

	handleDragLeaveVideo = () => {
		this.setState({ isDraggingVid: false });
	};

	handleDropVideo = (event) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0];
		if (file) {
			this.setVideoFile({ target: { files: [file] } });
		}
		this.setState({ isDraggingVid: false });
	};



	setImageFile = (event) => {
		const file = event.target.files[0];
		const fileData = {};
		fileData["name"] = file["name"];
		fileData['size'] = file['size'];
		fileData['extension'] = HF.returnExtFromImage(file['name']).toLowerCase();
		fileData['type'] = file['type'];
		fileData['lastModified'] = file['lastModified'];
		const reader = new FileReader();

		// Define allowed extensions
		const allowedExtensions = ['png', 'jpeg', 'jpg', 'gif', 'heic'];
		const fileExtension = fileData['extension']; // Get the extension
		// Check if the file extension is allowed
		if (!allowedExtensions.includes(fileExtension)) {
			this.setState({ errorImg: 'Only PNG, JPEG, JPG, and GIF, images are allowed.' });
			return;
		}
		reader.onload = () => {
			fileData['file'] = reader.result;
			const imagePreviewUrl = URL.createObjectURL(file);
      this.setState({magePreviewUrl:null, errorImg: null})
			fileData['preview'] = imagePreviewUrl;
			this.setState({
				imagePreviewUrl: imagePreviewUrl,
				// imageFile: fileData,
				audio: { ...this.state.audio, image: fileData },
			});
		};
		reader.readAsDataURL(file);
	};
	handleDragOver = (event) => {
		event.preventDefault();
		this.setState({ isDragging: true });
	};

	handleDragLeave = () => {
		this.setState({ isDragging: false });
	};

	handleDrop = (event) => {
		event.preventDefault();		
		const file = event.dataTransfer.files[0];
		if (file) {
			this.setImageFile({ target: { files: [file] } });
		}
		this.setState({ isDragging: false });
	};

	render(){
		const {videoFileName, videoAdded, upload, isDragging, isDraggingVid, video,formType, allowedFormatsStringMime, allowedFormatsStringExt, allowedFormatsVideoMime, allowedFormatsVideoExt} = this.state;
		const { users } = this.props;
        console.log('users',users);
		return(
			<form onSubmit={this.handleSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="video_id" className="form-control"  value={video.id}/>
			    }
			  <div className="row">
			    <div className="col-md-8">
					<div className='form-group'>
						<label htmlFor="video_title">Title<span className="requiredClass">*</span></label>
						<input type="text" name="video_title" className="form-control"  value={video.video_title} onChange={this.handleRec} />
						{this.validatorForm.message('video_title', video.video_title, 'required|min:5')}
					</div>
			    </div>
                </div>
                <div className="row">
			    <div className="col-md-4">
	              <div className=''>
	                <label className="container-check checkbox_down"> Is Active?
	                  <input type="checkbox" name="is_active" className="checkbox" value={video.is_active? 1 : 0} onChange={this.handleRec} checked={video.is_active?1:0} />
	                  <span className="checkmark"></span>
	                </label>
	                {/* {this.validatorPermission.message('is_active', video.is_active, 'required')} */}
	              </div>
	            </div>

                <div className="col-md-4">
	              <div className=''>
	                <label className="container-check checkbox_down"> Allow Comments?
	                  <input type="checkbox" name="is_allow_comments" className="checkbox" value={video.is_allow_comments? 1 : 0} onChange={this.handleRec} checked={video.is_allow_comments?1:0} />
	                  <span className="checkmark"></span>
	                </label>
	                {/* {this.validatorPermission.message('is_allow_comments', video.is_allow_comments, 'required')} */}
	              </div>
	            </div>

                <div className="col-md-4">
	              <div className=''>
	                <label className="container-check checkbox_down"> Allow Caret Creation?
	                  <input type="checkbox" name="is_allow_caretCreation" className="checkbox" value={video.is_allow_caretCreation? 1 : 0} onChange={this.handleRec} checked={video.is_allow_caretCreation?1:0} />
	                  <span className="checkmark"></span>
	                </label>
	                {/* {this.validatorPermission.message('is_allow_caretCreation', video.is_allow_caretCreation, 'required')} */}
	              </div>
	            </div>

			  </div>
              <div className="row">
			    <div className="col-md-12">
			      <div className='form-group'>
			        <label htmlFor="video_description">Description<span className="requiredClass">*</span></label>
			        <textarea name="video_description" className="form-control"  onChange={this.handleRec}>
                    {video.video_description}
                    </textarea>
			        { formType === 'add' &&
			        	this.validatorForm.message('video_description', video.video_description, 'required|min:5')
			        }
			      </div>
			    </div>
			  </div>

                <div className="row">
                    <div className="col-md-12">
                    <div className='form-group'>
                        <label htmlFor="name">Select User<span className="requiredClass">*</span></label>
                        <Select
                            value={HF.returnLabelsWithValues(video.users, users)}
                            onChange={this.handleChangeCategory}
                            options={users}
                            isSearchable={true}
                            isMulti={true}
                            placeholder={'Select User'}
                            isClearable={false}
                        />
                        {this.validatorForm.message('users', video.users, 'required|min:1,array')}
                    </div>
                    </div>
                </div>
				
				<div className='row'>
					<div className="col-md-5 pofilePicContainer">
						<div className=''>
							<label>Select Image</label>
							{/* <div className='uploadBtn'> */}
								<div
								className={`uploadBtn ${isDragging ? 'dragging' : ''}`}
								onDragOver={this.handleDragOver}
								onDragLeave={this.handleDragLeave}
								onDrop={this.handleDrop}
								>
								<label for="upload-image" id='upload-image-btn'>
								<img src={fileUploadIcon} alt={fileUploadIcon} width={20} />
								<span id="text">
									{this.state.imageFile ? this.state.imageFile.name : "Drop / Upload image file"}
								</span>
								</label>
								<input
								type="file"
								accept="image/png, image/jpeg, image/jpg, image/gif"
								id="upload-image"
								onChange={this.setImageFile}
								/>
							</div>
							{this.state.errorImg && <p className='srv-validation-message'>{this.state.errorImg}</p>}
							{this.state.imagePreviewUrl && (
								<div className='text-center'>
								<img src={this.state.imagePreviewUrl} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
								</div>
							)}
						</div>
					</div>

					<div className="col-md-1"></div>

					<div className="col-md-6">
						<div className=''>
							<label>Select Video</label>
							{/* <div className='uploadBtn'> */}
							<div
								className={`uploadBtn ${isDraggingVid ? 'dragging' : ''}`}
								onDragOver={this.handleDragOverVideo}
								onDragLeave={this.handleDragLeaveVideo}
								onDrop={this.handleDropVideo}
							>
								<label for="upload" id='upload-btn' className={`${videoAdded ? "chosen" : ""}`}>
									<img src={upload} alt={upload} width={20} />
									<span id="text">
										{videoFileName ? videoFileName : "Drop / Upload video file"}
									</span>
								</label>
								<input
									type="file"
									accept="video/quicktime, video/mp4, video/x-flv, video/webm"
									id="upload"
									onChange={this.setVideoFile}
								/>
							</div>
							{this.validatorForm.message('videoFile', this.state.videoPreviewUrl, 'required')}
							{this.state.error && <p className='srv-validation-message'>{this.state.error}</p>}
							{this.state.videoPreviewUrl && (
								<div className='text-center'>
									<video controls style={{ maxWidth: '100%', height: 'auto' }}>
										<source src={this.state.videoPreviewUrl} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								</div>
							)}
							{this.state.videoDuration && <p className='text-primary mt-1 text-center'>Video duration: {this.state.videoDuration} seconds</p>}
						</div>
					</div>


					
				</div>

                {/* <div className="row">
                <div className="col-md-4 pofilePicContainer">
				  {!this.state.video.image ?
			      <div className='form-group dropzone_profileImage'>

					<FileUpload
						files={this.state.video.image}
						setResourceFile={this.setResourceFile}
						allowedFormatsStringMimePro={allowedFormatsStringMime}
						allowedFormatsStringExtPro={allowedFormatsStringExt}
						multiple={false}
					/>
			      </div>
				  :
				  <ImagePreview
					  filesToPreview={{preview:this.state.video.image,name:'Image',index:0,type:'image/jpeg'}}
					  removeFile={this.removeFile}
				  />
				  }
			    </div>

                <div className="col-md-8 pofilePicContainer">
				  {!this.state.video.videoFile ?
			      <div className='form-group dropzone_profileImage'>

					<FileUpload
						files={this.state.video.videoFile}
						setResourceFile={this.setVideoFile}
						allowedFormatsStringMimePro={allowedFormatsVideoMime}
						allowedFormatsStringExtPro={allowedFormatsVideoExt}
						multiple={false}
					/>
			      </div>
				  :
				  <ImagePreview
					  filesToPreview={{preview:this.state.video.videoFile,name:'Video',index:0,type:'video/mp4'}}
					  removeFile={this.removeVideoFile}
				  />
				  }
			    </div>

			  </div> */}


			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default VideoForm;
